import { createDialog } from '@/site/shared/dialog/index.js';
export function createCommentPopup({
    content = '',
    cancelButtonText = '取消',
    confirmButtonText = '确认',
    isMobi = false,
    confirm = () => {},
    cancel = () => {},
    layoutClass = '',
}) {
    if (!isMobi && !$('.c_popup').length) {
        createDialog({
            content,
            autoHide: false,
            confirmButtonText,
            cancelButtonText,
            onConfirm: () => {
                confirm();
            },
            onCancel: () => {
                cancel();
            },
            onClose: () => {},
            layoutClass,
        });
    }
    if (isMobi && !$('.jz-modal-root').length) {
        Vue.prototype.$modal.show({
            title: content,
            onCancel() {
                cancel();
            },
            onConfirm() {
                confirm();
            },
            env: 'mobile',
            getContainer() {
                return document.querySelector('.jz_preview_area');
            },
            confirmButtonText,
            cancelButtonText,
        });
    }
}
